@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";

$margin: 2rem;

.container {
  li img, li video, li iframe {
    margin-left: 0 !important;

    & ~ div { // content container when media is present
      $mediaWidth: calc(50% - #{$margin});
      width: calc(100% - #{$mediaWidth} - #{$margin}) !important;

      @media (max-width: $break-point-sm) {
        width: 100% !important;
      }
    }

    &[data-smallwidth="true"] ~ div {
      $mediaWidth: 100px;
      width: calc(100% - #{$mediaWidth} - #{$margin}) !important;
    }
  }

  li > div > div:only-child { // contentContainer
    margin-left: 0;
  }

  > div { // fetch more container
    text-align: center;

    button {
      color: var(--blocksTextColor);
      padding: .5rem 1rem;
      border-radius: 30px;
    }
  }

}
.feedHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: $break-point-md){
    flex-direction: column;
  }
  &SmallBlock {
    flex-direction: column;
  }
  padding: 0;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .toggleButtonContainer{
    display: inline-block;
    .toggleButtons {
      box-shadow: 2px 2px 2px #ccc;
      background-color: #D1D1D1 !important;
      font-size: 1.5rem;

      input + label {
        padding: 8px 30px;
      }
      @include mixins.toggleColorMixin(var(--primaryNavBackground), var(--primaryNavBackgroundTextColor));
    }
  }
  .adminButtonContainer {
    display: block;
    margin-right: 2rem;
    .adminButton {
      background: var(--primaryNavBackground);
      border-radius: 5px;
      color: var(--primaryNavBackgroundTextColor);
      padding: .5rem 1rem;

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
  &:hover {
    .adminButtonContainer {
      display: inline-block;
    }
  }
}

